import {Link} from "../../atoms/link";
import { DrupalMenuLinkContent } from 'next-drupal';
import {SocialButtons} from "../../organisms/social";

interface MenuFooterProps {
  menu?: DrupalMenuLinkContent[];
}

export function MenuFooter({ menu, ...props }: MenuFooterProps) {

  if (!menu?.length) {
    return null;
  }

  return (
    <nav data-cy="footer-menu" {...props}>
      <ul className="grid text-center md:text-left grid-cols-1 md:grid-cols-5 gap-12 md:space-x-4 text-black">
        {menu?.map((item) => {
          return (
            <li key={item.id}>
              <div className="uppercase text-primary font-semibold text-lg mb-1">{item.title}</div>
              {item?.items?.length && (
                <ul>
                  {item.items.map((item, key) => (
                    <li key={item.id} className="pb-4 md:pb-1">
                      {item?.url && (
                        <Link href={item?.url ?? "/"} prefetch={false} title={item.title} className="footer-nav-link">{item.title}</Link>
                      )}
                      {!item.url && (
                        item.title
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          );
        })}
        <li className="max-md:-order-1">
          <SocialButtons />
        </li>
      </ul>
    </nav>
  );
}
