import {LinkProps} from "next/link";
import {Link} from "./link";
import {IconType, ImageIcon} from "./icon";
import {ReactElement} from "react";

interface Props {
  link: LinkProps,
  alt: string,
  height: number,
  width: number,
  icon: {
    type: string,
    color: string,
    stroke?: boolean,
  },
  className?: string,
}

/**
 * Renders a social link button.
 *
 * @constructor
 */
export function SocialButton ({...props}: Props): ReactElement {
  return (
    <Link {...props.link} className={props.className ?? ""} target="_blank">
      <ImageIcon type={props?.icon?.type as IconType} color={"primary"} height={props.width} width={props.height} alt={props.alt}/>
    </Link>
  );
}